import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

import Root from './containers/Root';
// import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

if (window.ssr) {
  loadableReady(() => {
    ReactDOM.hydrate(<Root />, rootElement);
  });
} else {
  ReactDOM.render(<Root />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
