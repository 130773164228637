import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import produce from 'immer';

import { createRequestActionTypes } from '../sagaHelper';
import {
  handleClearErrors,
  onFailure,
  onSuccessConcatList,
  handleResetList,
  onSuccessDetail,
  onSuccess,
  onSuccessList,
} from '../commonHandlers';
import { CREATE_PROFILE_SUCCESS, UPDATE_PROFILE_SUCCESS } from './profiles';

const initialState = {
  patient: null,
  paymentDetail: null,
  paidPaymentList: null,
  notPaidPaymentList: null,
  assignedFormsetList: null,
  myReservationList: null,
  assignedFormsetDetails: {},
  count: {},
  next: {},
  errors: {},
};

/** Action Types */
export const [
  REGISTER_PATIENT,
  REGISTER_PATIENT_SUCCESS,
  REGISTER_PATIENT_FAILURE,
] = createRequestActionTypes('hospitals/REGISTER_PATIENT');
export const [
  GET_PATIENT_INFO,
  GET_PATIENT_INFO_SUCCESS,
  GET_PATIENT_INFO_FAILURE,
] = createRequestActionTypes('hospitals/GET_PATIENT_INFO');
export const [
  GET_ASSIGNED_FORMSET_LIST,
  GET_ASSIGNED_FORMSET_LIST_SUCCESS,
  GET_ASSIGNED_FORMSET_LIST_FAILURE,
] = createRequestActionTypes('hospitals/GET_ASSIGNED_FORMSET_LIST');
export const [
  GET_ASSIGNED_FORMSET_DETAIL,
  GET_ASSIGNED_FORMSET_DETAIL_SUCCESS,
  GET_ASSIGNED_FORMSET_DETAIL_FAILURE,
] = createRequestActionTypes('hospitals/GET_ASSIGNED_FORMSET_DETAIL');
export const [
  CHECK_RESERVATIONS,
  CHECK_RESERVATIONS_SUCCESS,
  CHECK_RESERVATIONS_FAILURE,
] = createRequestActionTypes('hospitals/CHECK_RESERVATIONS');
export const [
  CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_FAILURE,
] = createRequestActionTypes('hospitals/CREATE_RESERVATION');
export const [
  CANCEL_RESERVATION,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_FAILURE,
] = createRequestActionTypes('hospitals/CANCEL_RESERVATION');
export const [
  GET_PAYMENT_DETAIL,
  GET_PAYMENT_DETAIL_SUCCESS,
  GET_PAYMENT_DETAIL_FAILURE,
] = createRequestActionTypes('hospitals/GET_PAYMENT_DETAIL');
export const [
  GET_MY_RESERVATION_LIST,
  GET_MY_RESERVATION_LIST_SUCCESS,
  GET_MY_RESERVATION_LIST_FAILURE,
] = createRequestActionTypes('hospitals/GET_MY_RESERVATION_LIST');
export const [
  APPROVE_PAYMENT,
  APPROVE_PAYMENT_SUCCESS,
  APPROVE_PAYMENT_FAILURE,
] = createRequestActionTypes('hospitals/APPROVE_PAYMENT');
export const [
  REFUSE_PAYMENT,
  REFUSE_PAYMENT_SUCCESS,
  REFUSE_PAYMENT_FAILURE,
] = createRequestActionTypes('hospitals/REFUSE_PAYMENT');
export const [
  GET_NOT_PAID_PAYMENT_LIST,
  GET_NOT_PAID_PAYMENT_LIST_SUCCESS,
  GET_NOT_PAID_PAYMENT_LIST_FAILURE,
] = createRequestActionTypes('hospitals/GET_NOT_PAID_PAYMENT_LIST');
export const [
  GET_PAID_PAYMENT_LIST,
  GET_PAID_PAYMENT_LIST_SUCCESS,
  GET_PAID_PAYMENT_LIST_FAILURE,
] = createRequestActionTypes('hospitals/GET_PAID_PAYMENT_LIST');
export const [
  SUBMIT_FORMSET,
  SUBMIT_FORMSET_SUCCESS,
  SUBMIT_FORMSET_FAILURE,
] = createRequestActionTypes('hospitals/SUBMIT_FORMSET');
export const [
  UPDATE_PATIENT,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAILURE,
] = createRequestActionTypes('hospitals/UPDATE_PATIENT');
const CLEAR_ERRORS = 'hospitals/CLEAR_ERRORS';
const RESET_LIST = 'hospitals/RESET_LIST';

/** Action Creators */
export const registerPatient = createAction(REGISTER_PATIENT);
export const getPatientInfo = createAction(GET_PATIENT_INFO);
export const getAssignedFormsetList = createAction(GET_ASSIGNED_FORMSET_LIST);
export const getAssignedFormsetDetail = createAction(
  GET_ASSIGNED_FORMSET_DETAIL,
);
export const getNotPaidPaymentList = createAction(GET_NOT_PAID_PAYMENT_LIST);
export const getPaidPaymentList = createAction(GET_PAID_PAYMENT_LIST);
export const checkReservations = createAction(CHECK_RESERVATIONS);
export const createReservation = createAction(CREATE_RESERVATION);
export const cancelReservation = createAction(CANCEL_RESERVATION);
export const getPaymentDetail = createAction(GET_PAYMENT_DETAIL);
export const getMyReservationList = createAction(GET_MY_RESERVATION_LIST);
export const approvePayment = createAction(APPROVE_PAYMENT);
export const refusePayment = createAction(REFUSE_PAYMENT);
export const submitFormset = createAction(SUBMIT_FORMSET);
export const updatePatient = createAction(UPDATE_PATIENT);
export const clearErrors = createAction(CLEAR_ERRORS);
export const resetList = createAction(RESET_LIST);

/** Selectors */
const errorsSelector = createSelector(
  (state) => state.errors,
  (errors) => ({ errors }),
);
const patientSelector = createSelector(
  (state) => state.patient,
  (patient) => ({ patient }),
);
const assignedFormsetListSelector = createSelector(
  (state) => state.assignedFormsetList,
  (assignedFormsetList) => ({
    compltedList: assignedFormsetList?.filter((o) => o.is_completed),
    notCompletedList: assignedFormsetList?.filter((o) => !o.is_completed),
  }),
);
const assignedFormsetDetailSelector = createSelector(
  (state) => state.assignedFormsetDetails,
  // (_, id) => id,
  // (details, id) => ({ detail: details[id] }),
  (details) => ({ details }),
);
const paymentDetailSelector = createSelector(
  (state) => state.paymentDetail,
  (paymentDetail) => ({ paymentDetail }),
);
const paidListSelector = createSelector(
  (state) => state.paidPaymentList,
  (paidPaymentList) => ({ paidPaymentList }),
);
const notPaidListSelector = createSelector(
  (state) => state.notPaidPaymentList,
  (state) => state.next.notPaidPaymentList,
  (notPaidPaymentList, next) => ({ notPaidPaymentList, next }),
);
const myReservationListSelector = createSelector(
  (state) => state.myReservationList,
  (myReservationList) => ({ myReservationList }),
);

export const selectors = {
  errors: (state) => errorsSelector(state.hospitals),
  patient: (state) => patientSelector(state.hospitals),
  assignedList: (state) => assignedFormsetListSelector(state.hospitals),
  assignedDetail: (state) => assignedFormsetDetailSelector(state.hospitals),
  paymentDetail: (state) => paymentDetailSelector(state.hospitals),
  paidList: (state) => paidListSelector(state.hospitals),
  notPaidList: (state) => notPaidListSelector(state.hospitals),
  myReservationList: (state) => myReservationListSelector(state.hospitals),
};

/** Reducer */
export default handleActions(
  {
    [REGISTER_PATIENT_FAILURE]: onFailure('registerPatient'),
    [CREATE_RESERVATION_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (state.myReservationList) {
          draft.myReservationList = [payload, ...state.myReservationList];
        }
      }),
    [CREATE_RESERVATION_FAILURE]: onFailure('createReservation'),
    [GET_PATIENT_INFO_SUCCESS]: onSuccess('patient'),
    [GET_PATIENT_INFO_FAILURE]: onFailure('patient'),
    [GET_ASSIGNED_FORMSET_LIST_SUCCESS]: onSuccessConcatList(
      'assignedFormsetList',
    ),
    [GET_ASSIGNED_FORMSET_LIST_FAILURE]: onFailure('assignedFormsetList'),
    [GET_ASSIGNED_FORMSET_DETAIL_SUCCESS]: onSuccessDetail(
      'assignedFormsetDetails',
      'id',
    ),
    [GET_ASSIGNED_FORMSET_DETAIL_FAILURE]: onFailure('assignedFormsetDetails'),
    [GET_PAYMENT_DETAIL_SUCCESS]: onSuccess('paymentDetail'),
    [GET_PAYMENT_DETAIL_FAILURE]: onFailure('paymentDetail'),
    [GET_NOT_PAID_PAYMENT_LIST_SUCCESS]: onSuccessList('notPaidPaymentList'),
    [GET_PAID_PAYMENT_LIST_SUCCESS]: onSuccessConcatList('paidPaymentList'),
    [GET_MY_RESERVATION_LIST_SUCCESS]: onSuccessList('myReservationList'),
    [APPROVE_PAYMENT_FAILURE]: onFailure('approvePayment'),
    [UPDATE_PATIENT_FAILURE]: onFailure('updatePatient'),
    [CANCEL_RESERVATION_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (state.myReservationList) {
          const nextList = [...state.myReservationList];
          const index = nextList.findIndex((o) => o.id === payload.id);
          if (index !== -1) {
            nextList.splice(index, 1);
          }
          draft.myReservationList = nextList;
        }
      }),
    [SUBMIT_FORMSET_FAILURE]: onFailure('submitFormset'),
    [SUBMIT_FORMSET_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (state.assignedFormsetList) {
          const nextList = [...state.assignedFormsetList];
          const index = nextList.findIndex((o) => o.id === payload.id);
          if (index !== -1) {
            nextList[index] = payload;
          }
          draft.assignedFormsetList = [payload, ...nextList];
        }
      }),
    [CLEAR_ERRORS]: handleClearErrors,
    [RESET_LIST]: handleResetList,
    // Other module
    [UPDATE_PROFILE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (state.patient) {
          draft.patient = {
            ...state.patient,
            name: payload?.name,
            phone_number: payload.phone_number,
          };
        }
      }),
    [CREATE_PROFILE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (state.patient) {
          draft.patient = {
            ...state.patient,
            name: payload?.name,
            phone_number: payload.phone_number,
          };
        }
      }),
  },
  initialState,
);
