import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

import { createRequestActionTypes } from '../sagaHelper';
import { onFailure, onSuccess } from '../commonHandlers';

const initialState = {
  myProfile: null,
  requestStatus: {},
  errors: {},
};

/** Action Types */
export const [
  GET_MY_PROFILE,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_FAILURE,
] = createRequestActionTypes('profiles/GET_MY_PROFILE');
export const [
  REQUEST_PHONE_CODE,
  REQUEST_PHONE_CODE_SUCCESS,
  REQUEST_PHONE_CODE_FAILURE,
] = createRequestActionTypes('profiles/REQUEST_PHONE_CODE');
export const [
  UPDATE_VERIFICATION_PROFILE,
  UPDATE_VERIFICATION_PROFILE_SUCCESS,
  UPDATE_VERIFICATION_PROFILE_FAILURE,
] = createRequestActionTypes('profiles/UPDATE_VERIFICATION_PROFILE');
export const [
  CONFIRM_ANONYMOUS_CODE,
  CONFIRM_ANONYMOUS_CODE_SUCCESS,
  CONFIRM_ANONYMOUS_CODE_FAILURE,
] = createRequestActionTypes('profiles/CONFIRM_ANONYMOUS_CODE');
export const [
  CREATE_PROFILE,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILURE,
] = createRequestActionTypes('profiles/CREATE_PROFILE');
export const [
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
] = createRequestActionTypes('profiles/UPDATE_PROFILE');
const CLEAR_PROFILE = 'profiles/CLEAR_PROFILE';
const CLEAR_ERRORS = 'profiles/CLEAR_ERRORS';

/** Action Creators */
export const getMyProfile = createAction(GET_MY_PROFILE);
export const requestPhoneCode = createAction(REQUEST_PHONE_CODE);
export const updateVerificationProfile = createAction(
  UPDATE_VERIFICATION_PROFILE,
);
export const confirmAnonymousCode = createAction(CONFIRM_ANONYMOUS_CODE);
export const createProfile = createAction(CREATE_PROFILE);
export const updateProfile = createAction(UPDATE_PROFILE);
export const clearProfile = createAction(CLEAR_PROFILE);
export const clearErrors = createAction(CLEAR_ERRORS);

/** Reducer */
export default handleActions(
  {
    [GET_MY_PROFILE_SUCCESS]: onSuccess('myProfile'),
    [GET_MY_PROFILE_FAILURE]: onFailure('myProfile'),
    [CREATE_PROFILE_FAILURE]: onFailure('createProfile'),
    [UPDATE_PROFILE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (state.myProfile) {
          draft.myProfile = {
            ...state.myProfile,
            name: payload?.name,
            phone_number: payload.phone_number,
          };
        }
      }),
    [UPDATE_PROFILE_FAILURE]: onFailure('updateProfile'),
    [REQUEST_PHONE_CODE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.requestStatus['requestCode'] = true;
        draft.errors['phoneVerification'] = null;
      }),
    [REQUEST_PHONE_CODE_FAILURE]: (state, { payload: { response = {} } }) =>
      produce(state, (draft) => {
        draft.requestStatus['requestCode'] = false;
        draft.errors['phoneVerification'] = response;
      }),
    [CONFIRM_ANONYMOUS_CODE_SUCCESS]: onSuccess('phoneVerification', false),
    [CONFIRM_ANONYMOUS_CODE_FAILURE]: onFailure('phoneVerification'),
    [CLEAR_PROFILE]: (state, action) => initialState,
    [CLEAR_ERRORS]: (state, action) => ({
      ...state,
      requestStatus: {},
      errors: {},
    }),
  },
  initialState,
);
