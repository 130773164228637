import React, { useCallback, forwardRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography, View } from '@memoryfactory/mui-components';
import Button from '@material-ui/core/Button';

import useLocalStorage from 'lib/utils/useLocalStorage';

const StyledDialog = withStyles((theme) => ({
  paper: {
    minWidth: 280,
  },
}))(Dialog);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NoticeDialog = () => {
  const [open, setOpen] = useLocalStorage('noticeDialog', 'true');

  const onClose = useCallback(() => {
    setOpen('');
  }, [setOpen]);
  const onClickButton = useCallback(() => {
    onClose();
    window.open(
      'https://crepe-x.notion.site/4353475fa811477fbc786168256309f0',
      '_blank',
    );
  }, [onClose]);

  return (
    <StyledDialog
      open={!!open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>회원 개인정보이전</DialogTitle>
      <DialogContent>
        <View pt={3} pb={4}>
          <Typography variant="body2" color="textSecondary" align="center">
            주식회사 하늘라이프케어 서비스의 제공자가
            <br />
            2022년 3월 1일 주식회사 마인드차트로 변경됨에 따라 개인정보 이전
            공지를 드립니다.
          </Typography>
        </View>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClickButton}>
          자세히보기
        </Button>
        <Button onClick={onClose}>더 이상 보지않기</Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default NoticeDialog;
