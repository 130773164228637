import { call, put, select } from 'redux-saga/effects';
import { replace, getSearch } from 'connected-react-router';
import queryString from 'query-string';
import { pathRegex } from '@memoryfactory/common';

import { startLoading, finishLoading } from './modules/loading';

export const createRequestActionTypes = (type) => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return [type, SUCCESS, FAILURE];
};

export const createRequestSaga = (type, request, sagaCallback) => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return function* (action) {
    yield put(startLoading(type));
    try {
      const { callback, generator, ...kwargs } = action.payload || {};
      const response = yield call(request, kwargs);
      yield put({
        type: SUCCESS,
        payload: response,
        meta: response,
      });
      if (callback) {
        yield callback(response);
      }
      if (generator) {
        yield* generator(response);
      }
      if (sagaCallback) {
        yield* sagaCallback(response);
      }
    } catch (e) {
      yield put({
        type: FAILURE,
        payload: e,
        error: true,
      });
    }
    yield put(finishLoading(type));
  };
};

export function* handleNextPath(nextUrl) {
  const search = yield select(getSearch);
  yield put(replace(`${nextUrl}${search}`));
}

export function* handleLoginSaga() {
  const { hasProfile, hasCode } = yield select(({ accounts }) => ({
    hasProfile: accounts?.hasProfile,
    hasCode: accounts?.hasCode,
  }));
  if (hasCode && hasProfile) {
    const search = yield select(getSearch);
    const nextPath = decodeURI(queryString.parse(search).nextPath);
    if (pathRegex.test(nextPath)) {
      yield put(replace(nextPath));
    }
  }
}
