import openColor from 'open-color';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: rgba(180, 180, 180, 0.3) transparent;
  }
  body {
    color: ${openColor.gray[8]};
    background-color: ${openColor.white};
    font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, Roboto,
      Helvetica, sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    letter-spacing: -.02em;
  }
  main {
    display: flex;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    .Content__Container {
      min-height: 100vh;
    }
  }
  a {
    all: unset;
    color: ${openColor.gray[8]};
    cursor: pointer;
  }
  input { 
    -webkit-user-select : auto;
  }
  .hide-scroll {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  *::-webkit-scrollbar {
    height: 8px;
  }
  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(180, 180, 180, 0.3);
  }
  *::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
`;
