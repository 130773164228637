import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

/** Action Types */
const SHOW_DIALOG = 'common/SHOW_DIALOG';
const HIDE_DIALOG = 'common/HIDE_DIALOG';
const SHOW_SNACKBAR = 'common/SHOW_SNACKBAR';
const HIDE_SNACKBAR = 'common/HIDE_SNACKBAR';

/** Action Creators */
export const showDialog = createAction(SHOW_DIALOG);
export const hideDialog = createAction(HIDE_DIALOG);
export const showSnackbar = createAction(SHOW_SNACKBAR);
export const hideSnackbar = createAction(HIDE_SNACKBAR);

// initial State
const initialState = {
  openDialog: false,
  openSnack: false,
  dialogData: null,
  snackMessage: '',
};

// Reducer
export default handleActions(
  {
    [SHOW_DIALOG]: (state, { payload }) =>
      produce(state, (draft) => {
        const { name, dialogData } = payload || {};
        const openTarget = name || 'openDialog';
        draft[openTarget] = true;
        draft.dialogData = dialogData;
      }),
    [HIDE_DIALOG]: (state, { payload: name }) =>
      produce(state, (draft) => {
        const openTarget = name || 'openDialog';
        draft[openTarget] = false;
        draft.dialogData = null;
      }),
    [SHOW_SNACKBAR]: (state, { payload: message }) =>
      produce(state, (draft) => {
        draft.openSnack = true;
        draft.snackMessage = message;
      }),
    [HIDE_SNACKBAR]: (state, action) =>
      produce(state, (draft) => {
        draft.openSnack = false;
        draft.snackMessage = '';
      }),
  },
  initialState,
);
