import client from './client';

const PREFIX = '/accounts';

export const emailSignup = ({ data }) => client.post(`${PREFIX}/signup/`, data);

export const emailLogin = ({ data }) =>
  client.post(`${PREFIX}/signin/email/`, data);

export const kakaoLogin = (data) =>
  client.post(`${PREFIX}/signin/kakao/`, data);

export const logout = () => client.delete(`${PREFIX}/logout/`);

export const leave = () => client.delete(`${PREFIX}/leave/`);

export const requestResetPassword = (data) =>
  client(`${PREFIX}/reset-password/`, {
    method: 'POST',
    data,
  });

export const resetPassword = ({ uidb, token, data }) =>
  client(`${PREFIX}/reset-password/${uidb}/${token}/`, {
    method: 'POST',
    data,
  });

export const validateResetForm = ({ uidb, token }) =>
  client(`${PREFIX}/reset-password/${uidb}/${token}/`, {
    method: 'GET',
  });
