import { takeLeading, takeEvery, put } from 'redux-saga/effects';

import * as profilesApi from 'lib/apis/profiles';
import { showSnackbar } from 'stores/modules/common';
import { createRequestSaga } from '../sagaHelper';
import {
  GET_MY_PROFILE,
  REQUEST_PHONE_CODE,
  CONFIRM_ANONYMOUS_CODE,
  CREATE_PROFILE,
  UPDATE_PROFILE,
} from '../modules/profiles';

const getMyProfile = createRequestSaga(
  GET_MY_PROFILE,
  profilesApi.getMyProfile,
);
const requestPhoneCode = createRequestSaga(
  REQUEST_PHONE_CODE,
  profilesApi.requestPhoneCode,
  function* () {
    yield put(showSnackbar('인증번호가 전송되었습니다'));
  },
);
// const updateVerificationProfile = createRequestSaga(
//   UPDATE_VERIFICATION_PROFILE,
//   profilesApi.updateVerificationProfile,
// );
const confirmAnonymousCode = createRequestSaga(
  CONFIRM_ANONYMOUS_CODE,
  profilesApi.confirmAnonymousCode,
);
const createProfile = createRequestSaga(
  CREATE_PROFILE,
  profilesApi.createProfile,
);
const updateProfile = createRequestSaga(
  UPDATE_PROFILE,
  profilesApi.updateProfile,
  function* () {
    yield put(showSnackbar('프로필을 수정했습니다'));
  },
);

export default function* profilesSaga() {
  yield takeEvery(GET_MY_PROFILE, getMyProfile);
  yield takeLeading(REQUEST_PHONE_CODE, requestPhoneCode);
  // yield takeLeading(UPDATE_VERIFICATION_PROFILE, updateVerificationProfile);
  yield takeLeading(CONFIRM_ANONYMOUS_CODE, confirmAnonymousCode);
  yield takeLeading(CREATE_PROFILE, createProfile);
  yield takeLeading(UPDATE_PROFILE, updateProfile);
}
