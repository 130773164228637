import {
  takeEvery,
  throttle,
  takeLeading,
  put,
  select,
} from 'redux-saga/effects';
import { replace, push, getSearch } from 'connected-react-router';
import { pathRegex } from '@memoryfactory/common';
import queryString from 'query-string';

import * as hospitalsApi from 'lib/apis/hospitals';
import { createRequestSaga } from '../sagaHelper';
import { logout } from 'stores/modules/accounts';
import { showSnackbar } from 'stores/modules/common';
import {
  REGISTER_PATIENT,
  GET_PATIENT_INFO,
  GET_ASSIGNED_FORMSET_LIST,
  GET_ASSIGNED_FORMSET_DETAIL,
  CHECK_RESERVATIONS,
  CREATE_RESERVATION,
  GET_PAYMENT_DETAIL,
  GET_NOT_PAID_PAYMENT_LIST,
  GET_PAID_PAYMENT_LIST,
  APPROVE_PAYMENT,
  REFUSE_PAYMENT,
  SUBMIT_FORMSET,
  GET_MY_RESERVATION_LIST,
  CANCEL_RESERVATION,
  UPDATE_PATIENT,
} from '../modules/hospitals';

const registerPatient = createRequestSaga(
  REGISTER_PATIENT,
  hospitalsApi.registerPatient,
  function* () {
    const search = yield select(getSearch);
    const nextPath = decodeURI(queryString.parse(search).nextPath);
    if (pathRegex.test(nextPath)) {
      yield put(replace(nextPath));
    } else {
      yield put(replace('/profiles'));
    }
  },
);
const getPatientInfo = createRequestSaga(
  GET_PATIENT_INFO,
  hospitalsApi.getPatientInfo,
);
const getAssignedFormsetList = createRequestSaga(
  GET_ASSIGNED_FORMSET_LIST,
  hospitalsApi.getAssignedFormsetList,
);
const getAssignedFormsetDetail = createRequestSaga(
  GET_ASSIGNED_FORMSET_DETAIL,
  hospitalsApi.getAssignedFormsetDetail,
);
const getNotPaidPaymentList = createRequestSaga(
  GET_NOT_PAID_PAYMENT_LIST,
  hospitalsApi.getPaymentList,
);
const getPaidPaymentList = createRequestSaga(
  GET_PAID_PAYMENT_LIST,
  hospitalsApi.getPaymentList,
);
const checkReservations = createRequestSaga(
  CHECK_RESERVATIONS,
  hospitalsApi.checkReservations,
);
const createReservation = createRequestSaga(
  CREATE_RESERVATION,
  hospitalsApi.createReservation,
  function* () {
    yield put(showSnackbar('예약이 접수되었습니다'));
    yield put(push('/profiles'));
  },
);
const getPaymentDetail = createRequestSaga(
  GET_PAYMENT_DETAIL,
  hospitalsApi.getPaymentDetail,
);
const approvePayment = createRequestSaga(
  APPROVE_PAYMENT,
  hospitalsApi.approvePayment,
  function* () {
    yield put(replace('/payments/complete'));
  },
);
const refusePaymentSaga = createRequestSaga(
  REFUSE_PAYMENT,
  hospitalsApi.refusePayment,
  function* () {
    yield put(replace('/'));
  },
);
const submitFormsetSaga = createRequestSaga(
  SUBMIT_FORMSET,
  hospitalsApi.submitFormset,
  function* () {
    yield put(showSnackbar('제출되었습니다'));
    yield put(push('/profiles'));
  },
);
const myReservationListSaga = createRequestSaga(
  GET_MY_RESERVATION_LIST,
  hospitalsApi.getMyRservationList,
);
const cancelReservationSaga = createRequestSaga(
  CANCEL_RESERVATION,
  hospitalsApi.cancelReservation,
  function* () {
    yield put(showSnackbar('예약이 취소되었습니다'));
  },
);
const updatePatientSaga = createRequestSaga(
  UPDATE_PATIENT,
  hospitalsApi.updatePatient,
  function* () {
    yield put(showSnackbar('병원정보가 바뀌었습니다. 다시 로그인해주세요'));
    yield put(logout());
  },
);

export default function* hospitalsSaga() {
  yield takeLeading(REGISTER_PATIENT, registerPatient);
  yield takeLeading(CREATE_RESERVATION, createReservation);
  yield takeEvery(GET_PATIENT_INFO, getPatientInfo);
  yield takeEvery(GET_ASSIGNED_FORMSET_DETAIL, getAssignedFormsetDetail);
  yield takeEvery(CHECK_RESERVATIONS, checkReservations);
  yield throttle(1000, GET_ASSIGNED_FORMSET_LIST, getAssignedFormsetList);
  yield throttle(1000, GET_PAYMENT_DETAIL, getPaymentDetail);
  yield throttle(1000, GET_PAID_PAYMENT_LIST, getPaidPaymentList);
  yield throttle(1000, GET_NOT_PAID_PAYMENT_LIST, getNotPaidPaymentList);
  yield throttle(1000, GET_MY_RESERVATION_LIST, myReservationListSaga);
  yield takeLeading(APPROVE_PAYMENT, approvePayment);
  yield takeLeading(REFUSE_PAYMENT, refusePaymentSaga);
  yield takeLeading(SUBMIT_FORMSET, submitFormsetSaga);
  yield takeLeading(CANCEL_RESERVATION, cancelReservationSaga);
  yield takeLeading(UPDATE_PATIENT, updatePatientSaga);
}
