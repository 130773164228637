import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';

import * as sagas from './sagas';
import * as reducers from './modules';

export const persistCookieOptions = {
  expiration: { default: 365 * 86400 },
};

const persistConfig = {
  key: 'skymental',
  storage: new CookieStorage(Cookies, persistCookieOptions),
  whitelist: ['accounts'],
};

export const configurePersistor = (store) => persistStore(store, {});

export const createRootReducer = (history) =>
  persistReducer(
    persistConfig,
    combineReducers({ ...reducers, router: connectRouter(history) }),
  );

export function* rootSaga() {
  yield all(Object.values(sagas).map((saga) => saga()));
}
