import LoggedInHOC from 'components/HOC/LoggedInHOC';

import {
  EmailLoginPage,
  LoginPage,
  SignupPage,
  ResetPasswordPage,
  RequestResetPasswordPage,
} from 'pages';

const routes = [
  {
    path: '/accounts/signup',
    component: SignupPage,
  },
  {
    path: '/accounts/login/email',
    component: EmailLoginPage,
  },
  {
    path: '/accounts/login',
    component: LoginPage,
  },
  {
    path: '/accounts/reset-password/:uidb/:token',
    component: ResetPasswordPage,
  },
  {
    path: '/accounts/reset-password',
    component: RequestResetPasswordPage,
  },
];

const PublicRoutes = routes.map(({ component, path }) => {
  return {
    path,
    component: LoggedInHOC(component, false),
  };
});

export default PublicRoutes;
