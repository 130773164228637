/* eslint-disable no-bitwise */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import nextPath from 'lib/utils/nextPath';

export default (ComposedComponent, required) => {
  const HasCodeHOC = ({ location, CommonActions, ...routerProps }) => {
    const { hasCode } = useSelector(({ accounts }) => ({
      hasCode: accounts.hasCode,
    }));
    const next = location.pathname;
    const qs = location.search;
    if (!hasCode && required) {
      return <Redirect to={`/register-code?nextPath=${next}${qs}`} />;
    }
    if (hasCode && !required) {
      return <Redirect to={nextPath('/', location.search)} />;
    }
    return <ComposedComponent location={location} {...routerProps} />;
  };

  return HasCodeHOC;
};
