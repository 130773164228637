import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@memoryfactory/mui-components';

import { initAuth } from 'stores/modules/accounts';
import { showSnackbar, hideSnackbar } from 'stores/modules/common';
import { PublicRoutes, PrivateRoutes, CommonRoutes } from 'routes';
import { NotFoundPage } from 'pages';
import client from 'lib/apis/client';
import NoticeDialog from 'components/Dialogs/NoticeDialog';

const App = () => {
  const dispatch = useDispatch();
  const routes = PrivateRoutes.concat(PublicRoutes, CommonRoutes);

  const { openSnack, snackMessage } = useSelector(({ common }) => ({
    openSnack: common.openSnack,
    snackMessage: common.snackMessage,
  }));
  const onClose = useCallback(() => dispatch(hideSnackbar()), [dispatch]);

  if (!client.interceptors.response?.handlers.length) {
    client.interceptors.response.use(
      (response) => response.data,
      (error) => {
        const { status } = error.response || {};
        if (status === 401) {
          dispatch(initAuth());
        } else if (status === 500) {
          dispatch(showSnackbar('서버에 일시적인 문제가 발생했습니다'));
        }
        return Promise.reject(error);
      },
    );
  }

  return (
    <>
      <Helmet>
        <title>마인드차트</title>
      </Helmet>
      <Switch>
        {routes.map(({ path, component, exact }, index) => (
          <Route
            key={`Route${index}`}
            path={path}
            component={component}
            exact={exact}
          />
        ))}
        <Route path="*" component={NotFoundPage} />
      </Switch>
      <Snackbar open={openSnack} message={snackMessage} onClose={onClose} />
      <NoticeDialog />
    </>
  );
};

export default App;
