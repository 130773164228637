import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

import { createRequestActionTypes } from '../sagaHelper';
import { REGISTER_PATIENT_SUCCESS } from './hospitals';
import {
  handleClearErrors,
  handleUpdateField,
  onFailure,
} from '../commonHandlers';
import { CREATE_PROFILE_SUCCESS, UPDATE_PROFILE_SUCCESS } from './profiles';

const initialState = {
  isLoggedIn: false,
  hasCode: false,
  hasProfile: false,
  user: null,
  errors: {},
};

/** Action Types */
export const [
  KAKAO_LOGIN,
  KAKAO_LOGIN_SUCCESS,
  KAKAO_LOGIN_FAILURE,
] = createRequestActionTypes('accounts/KAKAO_LOGIN');
export const [
  EMAIL_SIGNUP,
  EMAIL_SIGNUP_SUCCESS,
  EMAIL_SIGNUP_FAILURE,
] = createRequestActionTypes('accounts/EMAIL_SIGNUP');
export const [
  EMAIL_LOGIN,
  EMAIL_LOGIN_SUCCESS,
  EMAIL_LOGIN_FAILURE,
] = createRequestActionTypes('accounts/EMAIL_LOGIN');
export const [
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_FAILURE,
] = createRequestActionTypes('accounts/REQUEST_RESET_PASSWORD');
export const [
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
] = createRequestActionTypes('accounts/RESET_PASSWORD');
export const [
  VALIDATE_RESET_FORM,
  VALIDATE_RESET_FORM_SUCCESS,
  VALIDATE_RESET_FORM_FAILURE,
] = createRequestActionTypes('accounts/VALIDATE_RESET_FORM');
export const [LEAVE, LEAVE_SUCCESS, LEAVE_FAILURE] = createRequestActionTypes(
  'accounts/LEAVE',
);
export const [LOGOUT, LOGOUT_SUCCESS] = createRequestActionTypes(
  'accounts/LOGOUT',
);
const INIT_AUTH = 'accounts/INIT_AUTH';
const UPDATE_FIELD = 'accounts/UPDATE_FIELD';
const CLEAR_ERRORS = 'accounts/CLEAR_ERRORS';

/** Action Creators */
export const kakaoLogin = createAction(KAKAO_LOGIN);
export const emailSignup = createAction(EMAIL_SIGNUP);
export const emailLogin = createAction(EMAIL_LOGIN);
export const leave = createAction(LEAVE);
export const logout = createAction(LOGOUT);
export const requestResetPassword = createAction(REQUEST_RESET_PASSWORD);
export const resetPassword = createAction(RESET_PASSWORD);
export const validateResetForm = createAction(VALIDATE_RESET_FORM);
export const initAuth = createAction(INIT_AUTH);
export const updateField = createAction(UPDATE_FIELD);
export const clearErrors = createAction(CLEAR_ERRORS);

/** Common handler */
const onSuccessAuth = (state, { payload: user }) =>
  produce(state, (draft) => {
    const { has_code, ...rest } = user;
    draft.user = rest;
    draft.hasCode = has_code;
    draft.hasProfile = !!user?.profile;
    draft.isLoggedIn = true;
    draft.errors = {};
  });
const onFailureAuth = (key) => (state, { payload: { response = {} } }) => ({
  ...initialState,
  isLoggedIn: false,
  hasCode: false,
  hasProfile: false,
  errors: { ...state.errors, [key]: response },
});

/** Reducer */
export default handleActions(
  {
    [EMAIL_LOGIN_SUCCESS]: onSuccessAuth,
    [EMAIL_LOGIN_FAILURE]: onFailureAuth('auth'),
    [EMAIL_SIGNUP_SUCCESS]: onSuccessAuth,
    [EMAIL_SIGNUP_FAILURE]: onFailureAuth('auth'),
    [KAKAO_LOGIN_SUCCESS]: onSuccessAuth,
    [KAKAO_LOGIN_FAILURE]: onFailureAuth('socialAuth'),
    [REQUEST_RESET_PASSWORD_FAILURE]: onFailureAuth('auth'),
    [RESET_PASSWORD_FAILURE]: onFailureAuth('auth'),
    [VALIDATE_RESET_FORM_FAILURE]: onFailure('validateResetForm'),
    [LEAVE_FAILURE]: onFailure('leave'),
    [LEAVE_SUCCESS]: (state, action) => initialState,
    [INIT_AUTH]: (state, action) => initialState,
    [LOGOUT_SUCCESS]: (state, action) => initialState,
    [UPDATE_FIELD]: handleUpdateField,
    [CLEAR_ERRORS]: handleClearErrors,
    // Other module
    [REGISTER_PATIENT_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.hasCode = true;
      }),
    [CREATE_PROFILE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (draft.user) {
          draft.user = { ...draft.user, profile: payload };
          draft.hasProfile = true;
        }
      }),
    [UPDATE_PROFILE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        if (draft.user) {
          draft.user = { ...draft.user, profile: payload };
          draft.hasProfile = true;
        }
      }),
  },
  initialState,
);
