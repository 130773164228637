import React, { useEffect } from 'react';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { composeWithDevTools } from 'redux-devtools-extension';
import 'reset-css';

import {
  createRootReducer,
  configurePersistor,
  rootSaga,
} from 'stores/configureStore';
import { muiTheme } from 'lib/styles/theme';
import GlobalStyle from 'lib/styles/GlobalStyle';
import App from './App';

const isDev = process.env.NODE_ENV === 'development';
const preloadedState = window.__PRELOADED_STATE__;

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware(history)];

const store = createStore(
  createRootReducer(history),
  preloadedState,
  isDev
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares),
);
const persistor = configurePersistor(store);

sagaMiddleware.run(rootSaga);

const Root = () => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={muiTheme}>
            <ConnectedRouter history={history}>
              <GlobalStyle />
              <App />
            </ConnectedRouter>
          </MuiThemeProvider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  );
};

export default Root;
