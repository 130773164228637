import qs from 'query-string';

import client from './client';

const PREFIX = '/hospitals';

/* GET */
export const getPatientInfo = () => client.get(`${PREFIX}/me/`);

export const getAssignedFormsetList = ({ page }) =>
  client.get(`${PREFIX}/me/forms/?${qs.stringify({ page })}`);

export const getAssignedFormsetDetail = ({ id }) =>
  client.get(`${PREFIX}/me/forms/${id}/`);

export const checkReservations = ({ date, doctor }) =>
  client.get(`${PREFIX}/reservations/check/?${qs.stringify({ date, doctor })}`);

export const getPaymentDetail = ({ id }) =>
  client.get(`${PREFIX}/payments/${id}/`);

export const getPaymentList = ({ page, status }) =>
  client.get(`${PREFIX}/me/payments/?${qs.stringify({ page, status })}`);

export const getMyRservationList = ({ page, dayStart }) =>
  client.get(
    `${PREFIX}/me/reservations/?${qs.stringify({ page, day_after: dayStart })}`,
  );

/* POST */
export const registerPatient = (data) =>
  client.post(`${PREFIX}/register-patient/`, data);

export const createReservation = (data) =>
  client.post(`${PREFIX}/reservations/`, data);

/* PATCH */
export const approvePayment = ({ id, data }) =>
  client.patch(`${PREFIX}/payments/${id}/`, data);

export const submitFormset = ({ id, data }) =>
  client.patch(`${PREFIX}/me/forms/${id}/`, data);

export const updatePatient = ({ id, data }) =>
  client.patch(`${PREFIX}/me/patients/`, data);

/* DELETE */
export const refusePayment = ({ id }) =>
  client.delete(`${PREFIX}/payments/${id}/`);

export const cancelReservation = ({ id }) =>
  client.delete(`${PREFIX}/me/reservations/${id}/`);
