import {
  SensitiveTermPage,
  UsageTermPage,
  PrivacyTermPage,
  AboutPage,
  HomePage,
} from 'pages';

export default [
  {
    path: '/agreements/sensitive',
    component: SensitiveTermPage,
  },
  {
    path: '/agreements/usage',
    component: UsageTermPage,
  },
  {
    path: '/agreements/privacy',
    component: PrivacyTermPage,
  },
  {
    path: '/about',
    component: AboutPage,
  },
  {
    path: '/',
    component: HomePage,
    exact: true,
  },
];
