import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import nextPath from 'lib/utils/nextPath';

export default (ComposedComponent, required) => {
  const LoggedInHOC = ({
    isLoggedIn,
    location,
    CommonActions,
    ...routerProps
  }) => {
    if (isLoggedIn ^ required) {
      if (isLoggedIn && !required) {
        return <Redirect to={nextPath('/', location.search)} />;
      } else {
        const nextPath = location.pathname;
        const qs = location.search;
        return <Redirect to={`/accounts/login?nextPath=${nextPath}${qs}`} />;
      }
    }
    return <ComposedComponent location={location} {...routerProps} />;
  };
  return connect(
    (state) => ({
      isLoggedIn: state.accounts.isLoggedIn,
    }),
    null,
  )(LoggedInHOC);
};
