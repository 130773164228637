/* eslint-disable no-bitwise */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import nextPath from 'lib/utils/nextPath';

export default (ComposedComponent, required) => {
  const HasProfileHOC = ({ location, CommonActions, ...routerProps }) => {
    const { hasProfile } = useSelector(({ accounts }) => ({
      hasProfile: accounts.hasProfile,
    }));
    const next = location.pathname;
    const qs = location.search;

    if (!hasProfile && required) {
      return <Redirect to={`/register-profile?nextPath=${next}${qs}`} />;
    }
    if (hasProfile && !required) {
      return <Redirect to={nextPath('/', location.search)} />;
    }
    return <ComposedComponent location={location} {...routerProps} />;
  };

  return HasProfileHOC;
};
