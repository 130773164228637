import loadable from '@loadable/component';

export const LoginPage = loadable(() => import('./LoginPage'));
export const EmailLoginPage = loadable(() => import('./EmailLoginPage'));
export const RegisterPatientPage = loadable(() =>
  import('./RegisterPatientPage'),
);
export const SignupPage = loadable(() => import('./SignupPage'));
export const RegisterProfilePage = loadable(() =>
  import('./RegisterProfilePage'),
);
export const RequestResetPasswordPage = loadable(() =>
  import('./RequestResetPasswordPage'),
);
export const ResetPasswordPage = loadable(() => import('./ResetPasswordPage'));
