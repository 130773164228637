import produce from 'immer';

export const onSuccess = (key, willSave = true) => (state, { payload }) =>
  produce(state, (draft) => {
    if (payload && willSave) {
      draft[key] = payload;
    }
    draft.errors[key] = null;
  });

export const onFailure = (key) => (state, { payload: { response = {} } }) =>
  produce(state, (draft) => {
    draft.errors[key] = response;
  });

export const onSuccessList = (key) => (
  state,
  { payload: { results, next, count } },
) =>
  produce(state, (draft) => {
    draft[key] = results;
    draft.next[key] = next;
    draft.count[key] = count;
    draft.errors[key] = null;
  });

export const onSuccessConcatList = (key) => (
  state,
  { payload: { results, next, count } },
) =>
  produce(state, (draft) => {
    const list = draft[key] || [];
    draft[key] = list.concat(results);
    draft.next[key] = next;
    draft.count[key] = count;
    draft.errors[key] = null;
  });

export const onSuccessDetail = (key, field = 'slug') => (state, { payload }) =>
  produce(state, (draft) => {
    const detailContainer = draft[key];
    if (payload) {
      const containerId = payload[field];
      detailContainer[containerId] = payload;
    }
    draft.errors[key] = null;
  });

export const handleResetList = (state, { payload: key }) =>
  produce(state, (draft) => {
    draft[key] = null;
    draft.next[key] = null;
    draft.count[key] = null;
  });

export const handleClearErrors = (state, action) => ({
  ...state,
  errors: {},
});

export const handleUpdateField = (state, { payload: { key, value } }) =>
  produce(state, (draft) => {
    draft[key] = value;
  });
