import loadable from '@loadable/component';

export const AboutPage = loadable(() => import('./AboutPage'));
export const NotFoundPage = loadable(() => import('./NotFoundPage'));
export const PaymentProcessPage = loadable(() =>
  import('./PaymentProcessPage'),
);
export const PaymentCompletePage = loadable(() =>
  import('./PaymentCompletePage'),
);
export const PaymentFailedPage = loadable(() => import('./PaymentFailedPage'));
