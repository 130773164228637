import { takeLeading, put } from 'redux-saga/effects';
import { replace, push } from 'connected-react-router';

import * as accountsApi from 'lib/apis/accounts';
import { createRequestSaga, handleLoginSaga } from '../sagaHelper';
import {
  EMAIL_SIGNUP,
  EMAIL_LOGIN,
  KAKAO_LOGIN,
  LOGOUT,
  LEAVE,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_RESET_FORM,
} from '../modules/accounts';
import { clearProfile } from '../modules/profiles';
import { showSnackbar } from '../modules/common';

const emailSignup = createRequestSaga(EMAIL_SIGNUP, accountsApi.emailSignup);
const emailLogin = createRequestSaga(
  EMAIL_LOGIN,
  accountsApi.emailLogin,
  function* () {
    yield* handleLoginSaga();
  },
);
const kakaoLogin = createRequestSaga(
  KAKAO_LOGIN,
  accountsApi.kakaoLogin,
  function* () {
    yield* handleLoginSaga();
  },
);
const leave = createRequestSaga(LEAVE, accountsApi.leave, function* () {
  yield put(showSnackbar('그동안 이용해주셔서 감사합니다'));
  yield put(clearProfile());
});
const logout = createRequestSaga(LOGOUT, accountsApi.logout, function* () {
  yield put(clearProfile());
  yield put(replace('/'));
});
const requestResetPasswordSaga = createRequestSaga(
  REQUEST_RESET_PASSWORD,
  accountsApi.requestResetPassword,
  function* () {
    yield put(showSnackbar('발송되었습니다. 메일함을 확인해주세요.'));
    yield put(push('/accounts/login'));
  },
);
const resetPasswordSaga = createRequestSaga(
  RESET_PASSWORD,
  accountsApi.resetPassword,
  function* () {
    yield put(showSnackbar('처리되었습니다'));
    yield put(replace('/accounts/login'));
  },
);
const validateResetFormSaga = createRequestSaga(
  VALIDATE_RESET_FORM,
  accountsApi.validateResetForm,
);

export default function* accountsSaga() {
  yield takeLeading(EMAIL_SIGNUP, emailSignup);
  yield takeLeading(EMAIL_LOGIN, emailLogin);
  yield takeLeading(KAKAO_LOGIN, kakaoLogin);
  yield takeLeading(LEAVE, leave);
  yield takeLeading(LOGOUT, logout);
  yield takeLeading(REQUEST_RESET_PASSWORD, requestResetPasswordSaga);
  yield takeLeading(RESET_PASSWORD, resetPasswordSaga);
  yield takeLeading(VALIDATE_RESET_FORM, validateResetFormSaga);
}
