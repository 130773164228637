import LoggedInHOC from 'components/HOC/LoggedInHOC';
import HasCodeHOC from 'components/HOC/HasCodeHOC';
import HasProfileHOC from 'components/HOC/HasProfileHOC';

import {
  FormsetDetailPage,
  ReservationFormPage,
  ProfileHomePage,
  RegisterPatientPage,
  PaymentCompletePage,
  PaymentProcessPage,
  PaymentFailedPage,
  RegisterProfilePage,
} from 'pages';

const routes = [
  {
    path: '/payments/complete',
    component: PaymentCompletePage,
  },
  {
    path: '/payments/process/:id',
    component: PaymentProcessPage,
  },
  {
    path: '/payments/:status',
    component: PaymentFailedPage,
  },
  {
    path: '/formsets/:id',
    component: FormsetDetailPage,
  },
  {
    path: '/reservations',
    component: ReservationFormPage,
  },
  {
    path: '/register-profile',
    component: RegisterProfilePage,
    needCode: false,
    needProfile: false,
  },
  {
    path: '/profiles',
    component: ProfileHomePage,
  },
  {
    path: '/register-code',
    component: RegisterPatientPage,
    needCode: false,
  },
];

const PrivateRoutes = routes.map(
  ({ component, path, needCode = true, needProfile = true }) => {
    return {
      path,
      component: LoggedInHOC(
        HasCodeHOC(HasProfileHOC(component, needProfile), needCode),
        true,
      ),
    };
  },
);

export default PrivateRoutes;
