import client from './client';

const PREFIX = '/profiles';

export const getMyProfile = () => client.get(`${PREFIX}/me/`);

export const requestPhoneCode = ({ data }) =>
  client.post(`${PREFIX}/verifications/phone/generate-code/`, data);

export const regeneratePhoneCode = ({ data }) =>
  client.post(`${PREFIX}/verifications/phone/regenerate-code/`, data);

export const confirmAnonymousCode = ({ data }) =>
  client.post(`${PREFIX}/verifications/phone/anonymous-code/`, data);

export const updateVerificationProfile = ({ data }) =>
  client.patch(`${PREFIX}/verifications/phone/confirm-code/`, data);

export const createProfile = ({ data }) => client.post(`${PREFIX}/`, data);

export const updateProfile = ({ data }) => client.patch(`${PREFIX}/me/`, data);
